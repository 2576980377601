// import { io } from 'socket.io-client';

// Cấu hình kết nối Socket.IO với URL từ môi trường
// const socket = io(process.env.VUE_APP_SOCKET_URL);

// Hàm setup socket và cung cấp vào ứng dụng Vue
export function setupSocket(app) {
    // Cung cấp socket cho toàn bộ ứng dụng qua globalProperties
    console.log(app);
    // app.config.globalProperties.$socket = socket;

    // // Kiểm tra kết nối thành công
    // socket.on('connect', () => {
    //     console.log('Connected to Socket.IO server');
    // });
    //
    // socket.on('connect_error', (err) => {
    //     console.log('Connection error:', err);
    // });
    // socket.on('disconnect', () => {
    //     console.log('Disconnected from server');
    // });
}
